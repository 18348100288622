import React from 'react'
import { queryCache, useMutation } from 'react-query'
import { trackCancellationComplete } from '~/analytics/events'
import {
  CACHE_KEY_SUBSCRIPTION,
  CACHE_KEY_UPCOMING_ORDER,
} from '~/bb-api/constants'
import { UPDATE_SUBSCRIPTION } from '~/bb-api/endpoints'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import { stripLeadingSlash } from '~/utils'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'

export const mutateCancelSubscription = () => {
  const user = React.useContext(UserContext)
  const { subscription } = React.useContext(SubscriptionContext)
  const history = React.useContext(ReachRouterHistoryContext)

  const cancelReason = history?.getHistoryEntry(1).pathname
  const cancelDetails = history?.getHistoryEntry(2).state?.text

  const url = UPDATE_SUBSCRIPTION(user.id, subscription.id)

  const deleteSubscription = async () =>
    axios
      .delete(url, {
        data: {
          details: cancelDetails ?? '', // required field, so ensure it is being sent no matter what
          reason: stripLeadingSlash(cancelReason),
        },
      })
      .then((response) => {
        /* In certain scenarios, axios returns undefined as the response.
         * Throwing an error ensures the onError handler for useMutation runs.
         */
        if (!response) {
          throw new Error(
            `Failed to cancel subscription ${subscription.id}. There was a problem with the request: ${url}`
          )
        }

        return response
      })

  return useMutation(deleteSubscription, {
    onSuccess() {
      if (history?.getHistoryEntry)
        trackCancellationComplete(cancelReason, cancelDetails)

      queryCache.invalidateQueries(CACHE_KEY_SUBSCRIPTION)
      queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)
    },
    onError(e: any) {
      captureNetworkError(e)
    },
  })
}
