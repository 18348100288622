import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import { PanelStopSubscriptionUIProps } from './PanelStopSubscription.types'

const PanelStopSubscriptionUI: React.FC<PanelStopSubscriptionUIProps> = ({
  panel,
  isLoading,
  handleClick,
}) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body pb={rem(16)}>{panel.subhead}</Body>
      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        {isLoading ? (
          <LoadingSpinner size={'small'} />
        ) : (
          <ModalItemSmall
            data-cy={TEST_ID.CANCEL_FLOW_STOP_SUBSCRIPTION}
            onClick={handleClick}
          >
            Stop my subscription
          </ModalItemSmall>
        )}
      </Grid>
    </PanelLayout>
  )
}

export default PanelStopSubscriptionUI
