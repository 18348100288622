import { useNavigate } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { mutateCancelSubscription } from '~/hooks/mutateCancelSubscription'
import { captureNetworkError } from '~/analytics/errors'
import PanelStopSubscriptionUI from './PanelStopSubscription.ui'
import type { CancelPanelProps } from '../../CancelFlowPanel.types'

const PanelStopSubscription: React.FC<CancelPanelProps> = ({
  panel,
  handleModalClose,
}) => {
  const navigate = useNavigate()
  const [cancelSubscription, { isLoading }] = mutateCancelSubscription()
  const showToast = React.useContext(ToastContext)

  const handleClick = async () => {
    cancelSubscription(undefined, {
      onSuccess() {
        navigate('/confirmed-stopped')
      },
      onError(e: any) {
        captureNetworkError(e)
        showToast('error', {
          children:
            'There was an issue canceling your subscription. Please try again or contact customer service to continue.',
        })
        handleModalClose()
      },
    })
  }

  return (
    <PanelStopSubscriptionUI
      handleClick={handleClick}
      isLoading={isLoading}
      panel={panel}
    />
  )
}

export default PanelStopSubscription
